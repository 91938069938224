<template>
  <div>
    <div class="wallet-box" v-if="showDialog">
      <div class="header">
        <span class="title">Connect Wallet</span>
        <a-icon class="close-icon" type="close" @click="changeVisible"/>
      </div>

      <div class="wallet-container">
        <div class="wallet-item" @click="connectMetamaskWallet" :class="{disabled: isLoading}">
          <img class="metamask-icon" v-if="walletNameM === 'MetaMask'" src="@/assets/wallets/metamask.svg">
          <img class="metamask-icon" v-else-if="walletNameM === 'TP Wallet' " src="@/assets/wallets/tp.png">
          <img class="metamask-icon" v-else-if="walletNameM === 'BitKeep'" src="@/assets/wallets/bitKeep.png">
          <img class="metamask-icon" v-else-if="walletNameM === 'imToken'" src="@/assets/wallets/imToken.svg">
          <img class="metamask-icon" v-else-if="walletNameM === 'Safff Wallet'" src="@/assets/wallets/safff.webp">
          <span class="wallet-name">{{ walletNameM }}</span>
          <img class="loading-icon" v-show="isLoading" src="@/assets/spinner.gif"/>
        </div>
        <div class="wallet-item" @click="walletConnect" :class="{disabled: isLoading}">
          <img class="walletconnect-icon" src="@/assets/wallets/walletconnect.png">
          <span class="wallet-name">WalletConnect</span>
          <!-- <img class="loading-icon" src="@/assets/spinner.gif"/> -->
        </div>
      </div>
    </div>
    <div v-else-if="!login" class="content-wrap">
      <div class="content-body">
        <logo class="logo" :height="60"></logo>
        <div class="title">Welcome</div>
        <p class="text">
          By connecting your wallet and using DAOG ID DAPP, you agree to
          <br/>our
          <router-link to="/docs/terms-of-service">Terms of Service</router-link>
          and
          <router-link to="/docs/privacy-policy">Privacy Policy</router-link>
          .
        </p>
        <div class="sign-tip" v-show="showSignTip">
          <a-icon class="info-icon" type="info-circle" theme="filled"/>
        <span>Check your wallet for a signature request</span>
      </div>
      </div>

      <div class="footer">
        <div class="left" @click="cancelSign">Cancel</div>
        <div class="right" @click="acceptSign" :class="{disabled: isLoading}">Accept and sign</div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import logo from '@/components/logo.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  errorHandler,
  addSupportedChain,
  isMobile,
  createWalletConnect
} from '@/utils/func'

export default {
  name: 'wallet',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  components: {
    logo
  },

  data () {
    return {
      isLoading: false,
      showSignTip: false,
      wallet: {
        name: 'MeatMask'
      }
    }
  },

  computed: {
    ...mapGetters(['login', 'address', 'chainId']),
    showDialog () {
      return !this.address && !this.login
    },

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    walletNameM () {
      if (!window.ethereum) return 'MetaMask'

      if (isMobile() && window.innerWidth <= 960) {
        if (window.ethereum.isTokenPocket) {
          return 'TP Wallet'
        }

        if (window.ethereum.isImToken) {
          return 'imToken'
        }

        if (window.ethereum.isBitKeep) {
          return 'BitKeep'
        }

        if (window.ethereum.isSafematrix) {
          return 'Safff Wallet'
        }
      }
      return 'MetaMask'
    }
  },
  // created () {
  //   if (this.address) {
  //     this.showWallet = false
  //     this.showStatement = true
  //   }
  // },

  methods: {
    ...mapActions([
      'setLogin',
      'setChainId',
      'setAddress',
      'setReverseFullName'
    ]),

    async walletConnect () {
      // Default cached provider
      const provider = await createWalletConnect()

      if (window.walletConnectWeb3Provider && provider) {
        // Listen to EIP-1193 events to detect accounts and chain change and also disconnection.
        // Subscribe to accounts change
        window.walletConnectWeb3Provider.on('accountsChanged:', (accounts) => {
          console.log('accountsChanged:', accounts)
          this.setAddress(accounts[0])
        })

        // Subscribe to chainId change
        window.walletConnectWeb3Provider.on('chainChanged', (chainId) => {
          console.log('chainChanged:', chainId)
          this.setChainId('0x' + chainId.toString(16))
        })

        // Subscribe to session connection
        window.walletConnectWeb3Provider.on('connect', () => {
          console.log('connect')
        })

        // Subscribe to session disconnection, default cached provider is cleared,
        window.walletConnectWeb3Provider.on('disconnect', (code, reason) => {
          console.log(code, reason)
          delete window.walletConnectWeb3Provider
          window.localStorage.removeItem(this.address)
          this.setLogin(false)
          this.setAddress('')
          window.location.reload()
        })

        try {
          // const chainId = (await provider.getNetwork()).chainId
          const signer = await provider.getSigner()
          const chainId = '0x' + (await signer.getChainId()).toString(16)
          const address = await signer.getAddress()
          this.setState(address, chainId)

          console.log('Current signer:', address, 'chainId:', chainId)
        } catch (err) {
          this.changeVisible()
          console.log(err)
        }
      }
    },

    changeVisible () {
      this.$emit('visible', {
        visible: false
      })
      this.isLoading = false
      document.body.style.overflow = ''
    },

    cancelSign () {
      this.changeVisible()
    },

    // TODO
    async connectMetamaskWallet () {
      if (this.walletFilter()) {
        this.isLoading = true
        window.localStorage.removeItem('walletconnect')
        this.requestChainId()
      }
    },

    connectAccount () {
      this.requestAccounts()
      // if (isMobile()) {
      //   this.requestAccounts()
      //   return
      // }
      // this.requestPermissions()
    },

    requestChainId () {
      window.ethereum.request({
        method: 'eth_chainId'
      }).then(chainId => {
        this.setChainId(chainId)
        this.switchNetwork(chainId)
      }).catch(err => {
        this.isLoading = false
        errorHandler(err)
      })
    },

    switchNetwork (chainId) {
      if (chainId !== this.supportChainId) {
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: this.supportChainId }]
        }).then(() => {
          this.setChainId(this.supportChainId)
          this.connectAccount()
        }).catch(async err => {
          // add Chain succeeded
          if (await addSupportedChain(err)) {
            this.setChainId(this.supportChainId)
            this.connectAccount()
          } else {
            this.isLoading = false
          }
        })
      } else {
        this.connectAccount()
      }
    },

    requestPermissions () {
      window.ethereum
        .request({
          method: 'wallet_requestPermissions',
          params: [{ eth_accounts: {} }]
        }).then(permissions => {
          console.log('permissions:', permissions)
          const accountsPermission = permissions.find(
            permission => permission.parentCapability === 'eth_accounts'
          )
          if (accountsPermission) {
            const accounts = permissions[0].caveats[0].value
            console.log('accountsPermission:', accounts)
            this.setAddress(accounts[0])
            if (this.loggedIn(this.address)) {
              this.changeVisible()
            }
          }
        }).catch(err => {
          errorHandler(err)
        }).finally(() => {
          this.isLoading = false
        })
    },

    requestAccounts () {
      window.ethereum.request({
        method: 'eth_requestAccounts'
      }).then(accounts => {
        this.setAddress(accounts[0])
        if (this.loggedIn(accounts[0])) {
          this.changeVisible()
        }
      }).catch(err => {
        errorHandler(err)
      }).finally(() => {
        this.isLoading = false
      })
    },

    async acceptSign () {
      if (!this.address) return

      this.isLoading = true

      if (this.chainId !== this.supportChainId) {
        // Send JSON RPC requests
        const requestArguments = {
          method: 'wallet_switchEthereumChain', // only MetaMask series
          params: [{ chainId: this.supportChainId }]
        }

        if (!window.walletConnectWeb3Provider) {
          try {
            await window.ethereum.request(requestArguments)
            this.setChainId(this.supportChainId)
          } catch (err) {
            errorHandler(err)
            this.isLoading = false
            return
          }
        } else {
          const walletName = window.walletConnectWeb3Provider.wc._peerMeta.name
          console.log('walletName:', walletName)

          if (walletName === 'MetaMask') {
            try {
              await window.walletConnectWeb3Provider.request(requestArguments)
              this.setChainId(this.supportChainId)
            } catch (err) {
              errorHandler(err)
              this.isLoading = false
              return
            }
          }
        }
      }

      let currentWeb3Provider
      const timestamp = Date.now()
      const signData = `Welcome to DIDDAO!
      \nClick to sign in and accept the DIDDAO Terms of Service (https://diddao.io/docs/terms-of-service) and Privacy Policy (https://diddao.io/docs/privacy-policy).
      \nThis request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will be reset in one week. If you disconnect and reconnect your wallet, you need to sign again for authorization.
      \nWallet address: ${this.address}
      \nNonce: ${timestamp}`

      setTimeout(() => {
        this.showSignTip = true
      }, 1000)

      if (window.walletConnectWeb3Provider) {
        currentWeb3Provider = window.walletConnectWeb3Provider
      } else {
        currentWeb3Provider = window.ethereum
      }

      currentWeb3Provider.request({
        method: 'personal_sign',
        params: [signData, this.address]
      }).then(signature => {
        const loginInfo = JSON.stringify({
          isLogin: true,
          timestamp: timestamp,
          signData: signData,
          signature: signature,
          address: this.address,
          chainId: this.chainId
        })

        window.localStorage.setItem(this.address, loginInfo)
        this.setLogin(true)
        this.changeVisible()
      }).catch(err => {
        errorHandler(err)
      }).finally(() => {
        this.isLoading = false
        this.showSignTip = false
      })
    },

    loggedIn (address) {
      if (window.localStorage.getItem(address)) {
        const loginInfo = JSON.parse(window.localStorage.getItem(address))
        const due = loginInfo.timestamp + 24 * 3600 * 1000 * 7
        if (loginInfo.isLogin && due > Date.now()) {
          this.setLogin(loginInfo.isLogin)
          this.setAddress(loginInfo.address)
          this.setChainId(loginInfo.chainId)
          return true
        } else {
          window.localStorage.removeItem(address)
          return false
        }
      } else {
        return false
      }
    },

    walletFilter () {
      if (!window.ethereum) {
        window.open('https://metamask.io/download/')
        return false
      }

      if (isMobile() && window.innerWidth <= 960) {
        return true
      }

      // PC端过滤
      if (!window.ethereum.isMetaMask) {
        this.$message.info('Multiple Web3 plugin wallets are detected, disable them and make sure Metamask is installed and try again.')
        return false
      }

      const keys = Object.keys(window.ethereum).filter((key) => {
        return key.slice(0, 2) === 'is'
      })

      console.log('Keys:', keys)

      if (keys.length > 1) {
        this.$message.info('Multiple Web3 plugin wallets are detected, disable them and make sure Metamask is installed and try again.')
        return false
      }

      return true
    },

    setState (address, chainId) {
      // this.setLogin(isLogin)
      this.setAddress(address)
      this.setChainId(chainId)
      // this.changeVisible()
    }
  }
}

</script>

<style scoped lang="scss">
.wallet-box, .content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 480px;
  background: #E8E9FB;
  z-index: 1001;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0,0,0,.5);
}

.content-wrap {
  padding: 24px;
  width: 550px;
  height: 450px;
  background: #fff;
  animation: 1s ease-in-out 0s 1 normal none running fadeIn;

  @keyframes fadeIn {

    0% { opacity: 0 }
    100% { opacity: 1 }
  }
  .content-body {
    text-align: center;
    .title {
      padding: 16px 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.5;
    }

    img {
     width: 180px;
    }
    .text {
      // margin: 0px 0 24px;
      font-size: 16px;
      font-weight: 500;

      a {
        color: $blue;
      }
    }
    .sign-tip {
      margin-top: 48px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      color: rgb(12, 12, 13);
      .info-icon {
        font-size: 20px;
        color: #42E068;
        padding-right: 12px;
      }
    }
  }

  .footer {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    font-size: 16px;
    font-weight: 600;
    .left, .right {
      padding: 6px 24px;
      border-radius: 8px;
      color: $blue;
      background: #fff;
      border: 2px solid #ddd;
      cursor: pointer;
      user-select: none;
      &:hover {
        opacity: 0.7;
      }
    }
    .right {
      padding: 8px 24px;
      color: #fff;
      background: $mainBtnColor;
      border: none
    }
  }
}

.header {
  position: relative;
  padding: 24px 16px;
  width: calc(100% - 32px);
  text-align: center;
  border-bottom: 1px solid rgba(120,120,128,.2);
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(12, 12, 13);

    line-height: 21px;
    overflow: hidden;
    user-select: none;
  }

  .close-icon {
    position: absolute;
    right: 0;
    top: 12px;
    font-size: 20px;
  }
  .wallet-container {
    padding: 32px;
    width: calc(100% - 32px);

    .wallet-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 32px;
      height: 66px;
      border-radius: 12px;
      background: #fff;
      user-select: none;
      cursor: pointer;
      transition: all 0.3s;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        box-shadow: 0 0 10px #aaa;
      }
      &:active {
        opacity: 0.5;
      }
      .metamask-icon {
        width: 42px;
      }
      .walletconnect-icon {
        width: 48px;
        margin-left: 12px;
      }
      .loading-icon {
        width: 28px;
      }
      .wallet-name {
        font-size: 20px;
        font-weight: 500;
        color: #333;
      }
    }
  }

@media screen  and (max-width: $mobileWidth){
  .wallet-box {
    width: calc(100% - 32px);
    max-width: 480px;
  }

  .content-wrap {
    height: auto;
    width: calc(100% - 32px);
    max-width: 550px;
    min-height: 450px;
    padding: 16px;
    .footer{
      width: 100%;
      justify-content: center;
      align-items: normal;

      .left, .right{
        padding: 6px 12px;
      }

      .right{
        margin-left: 12px;
      }
    }
  }
}

</style>
