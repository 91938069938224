<template>
  <div v-if="show" class="footer">
    <div class="w1200 container">
      <div class="container-left">
        <!-- <img src="@/assets/logo_galaxy_white.png" class="logo"> -->
        <!-- <img src="@/assets/logo_galaxy.png" class="logo"> -->
        <logo class="logo" :height="40"></logo>
      </div>

      <div class="fun-container">
        <div class="title">JOIN THE COMMUNITY</div>
        <div class="fun-list">
          <div class="fun-item">
            <a href="https://twitter.com/DID_DAO" title="Twitter" target="_blank">
              <i class="iconfont icon-Twitter"></i>
            </a>
          </div>

          <div class="fun-item">
            <a href="https://www.t.me/diddao_io" title="Telegram" target="_blank">
              <i class="iconfont icon-Telegram"></i>
            </a>
          </div>

          <div class="fun-item">
            <a href="https://github.com/dao-galaxy/articles" title="Github" target="_blank">
              <i class="iconfont icon-Github"></i>
            </a>
          </div>

          <div class="fun-item">
            <a href="#"  title="Medium">
              <i class="iconfont icon-Medium"></i>
            </a>
          </div>

          <div class="fun-item">
            <a href="#"  title="Email">
              <i class="iconfont icon-Email"></i>
            </a>
          </div>

          <div class="fun-item">
            <a href="https://discord.gg/a3CJ6EhZkz" title="Discord" target="_blank">
              <i class="iconfont icon-Discord"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

      <div class="w1200 copyright" >
        <!-- <p>Copyright© 2022-2022 DAO Galaxy Foundation. All Rights reserved</p> -->
        <div class="text-container">
          <p class="text">Copyright© 2022-2023 DID DAO. All Rights Reserved.</p>
          <div class="items">
            <router-link to="/docs/terms-of-service">Terms of Service</router-link>
            <router-link to="/docs/privacy-policy">Privacy Policy</router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import logo from '@/components/logo.vue'

export default {
  name: 'pageFooter',
  components: {
    logo
  },
  data () {
    return {
      show: true,
      list: [
        { title: 'PRODUCT', subList: ['Download', 'State'] },
        { title: 'COMPANY', subList: ['About', 'job offer', 'Brand Promotion'] },
        { title: 'RESOURCES', subList: ['Schoolyard', 'Technical Support', 'Security', 'Blog', 'Feedback', 'Developers'] },
        { title: 'POLICY', subList: ['Clause', 'Privacy', 'A Cookie Is Set', 'COP', 'Feedback', 'Developers'] }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('~@/scss/iconfont.scss');

.footer{
  border-top: 1px solid #eef;
  min-width: 1200px;
  background: linear-gradient(#FBFBFF, #E9EAFF);
  height: 160px;
  overflow: hidden;

  .container {
    height: 120px;
    padding-top: 24px;
    display: flex;

    .container-left {
      flex: 1;
      text-align: left;

      .logo {
        height: 50px;
      }

      // .phone {
      //   color: #999999;
      //   color: #333;
      //   font-weight: bold;
      //   font-size: 24px;
      //   display: flex;
      //   align-items: center;
      //   margin: 16px 0;

      //   .phone-icon {
      //     width: 32px;
      //     height: 32px;
      //     margin-right: 8px;
      //     border-radius: 50%;
      //     border: 2px solid #999999;
      //     padding: 2px 3px;

      //     img {
      //       width: 20px;
      //       height: 20px;
      //     }
      //   }
      // }

    }

    .fun-container{
      width: 220px;
      text-align: left;

      .title {
        // margin-bottom: 8px;
      }

      .fun-list{
        display: flex;
        justify-content: space-between;

        .fun-item{

          a{
            color: #555;
            color: rgba(0, 0, 0, 0.65);
            color: $blueOth;
          }

          .iconfont{
            font-size: 28px;

            &:hover{
              opacity: 0.6;
            }
          }
        }
      }
    }

  }

  .copyright{
    font-size: 12px;

    .text-container {
      display: flex;
      justify-content: space-between;

      .items {
        margin-right: 40px;
        a {
          color: #2C3E50;
          &:last-child {
            margin-left: 16px;
          }
          &:hover {
            color: $blueOth;
          }
        }
     }
    }
  }

  @media screen  and (max-width: $mobileWidth){

      min-width: 0;
      height: auto;

      .container{
        display: block;
        width: 100%;
        height: auto;

        .logo{
          margin-bottom: 6px;
          ::v-deep img{
            height: 28px !important;
          }
        }

        .container-left{
          text-align: center;
        }

        .fun-container{
          text-align: center;
          margin: auto;
          .fun-list .fun-item .iconfont {
            font-size: 20px;
          }
        }
      }

      .copyright{
        margin-top: 12px;
        .text-container {
          flex-direction: column-reverse;
          justify-content: space-between;

        .items {
            margin-bottom: 12px;
            margin-right: 0;
          }
        }
      }
  }

}
</style>
