<template>
  <div class="page-header" :class="{ 'active': active }">
    <div class="header-container">
      <div class="header-left">
        <div class="logo" @click="toHomePage">
          <logo :height="40"></logo>
        </div>
        <!-- <div class="navbar">
          <router-link to="/docs">Docs</router-link>
        </div> -->
        <!-- Community Link -->
        <div class="dropdown">
          <a-dropdown
            placement="bottomCenter"
            :trigger="['click', 'hover']"
            >
            <div class="community">
              <span class="join">COMMUNITY</span>
              <img class="icon" src="@/assets/registration/community.png">
              <a-icon type="down" style="font-size: 16px; vertical-align: middle;"/>
            </div>
            <a-menu slot="overlay">
              <a-menu-item>
                <div class="navbar">
                  <a-icon class="icon" type="setting"/>
                  <a href="https://diddao.gitbook.io/docs" target="_blank">Documents</a>
                </div>
              </a-menu-item>
              <a-menu-item>
                <div class="navbar" title="Not yet open">
                  <a-icon class="icon disabled" type="setting"/>
                  <a href="#" class="disabled">Developers</a>
                </div>
              </a-menu-item>
              <a-menu-item>
                <div class="navbar" title="Not yet open">
                  <a-icon class="icon disabled" type="setting"/>
                  <a href="#" class="disabled">Applications</a>
                </div>
              </a-menu-item>
              <a-menu-item>
                <a href="https://twitter.com/DID_DAO" title="Twitter" target="_blank">
                  <i class="iconfont icon-Twitter"></i>
                  Twitter
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="https://www.t.me/diddao_io" title="Telegram" target="_blank">
                  <i class="iconfont icon-Telegram"></i>
                  Telegram
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="https://github.com/dao-galaxy/articles" title="Github" target="_blank">
                  <i class="iconfont icon-Github"></i>
                  Github
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="#" title="Medium">
                  <i class="iconfont icon-Medium"></i>
                  Medium
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="https://discord.gg/a3CJ6EhZkz" title="Discord" target="_blank">
                  <i class="iconfont icon-Discord"></i>
                  Discord
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="#" title="Email">
                  <i class="iconfont icon-Email"></i>
                  Email
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>

      <div class="menu-container">
        <a-icon class="menu-icon" type="menu" @click="openDrawer"/>
      </div>

      <template v-if="login && address">
        <div class="info">
            <a-dropdown class="switch-network" :trigger="['click', 'hover']">

              <div class="chain capsule-container" :class="{unknown: !getNetworkInfoByChainId.chainID}">
                <div class="line1"><a-icon type="global" style="font-size: 16px;"/></div>
                <div class="network-name" :class="{disabled: this.chainId !== supportChainId}">
                  <div class="network-item-icon">
                    <!-- <img v-if="this.chainId === '0x1'" :src="getNetworkIconByChainId" class="icon-eth-t">
                    <img v-else-if="this.chainId === '0x5'" :src="getNetworkIconByChainId" class="icon-goerli-t"> -->
                    <!-- <img v-else :src="getNetworkIconByChainId" > -->
                    <img :src="getNetworkIconByChainId" >
                  </div>
                  <div>{{ getNetworkNameByChainId }}</div>
                </div>
                <div class="line1"><a-icon type="down" class="icon" /></div>
              </div>

              <a-menu slot="overlay">
                <a-menu-item :key="index" v-for="(item, index) in chain.list" :class="{disabled: item.chainID !== supportChainId}">
                  <div class="network-item" @click="changeChain(item)">
                    <div class="network-item-icon">
                      <!-- <img v-if="item.name==='Ethereum'" :src="item.icon" class="icon-eth" /> -->
                      <img :src="item.icon"/>
                    </div>
                    <div>{{ item.name }}</div>
                  </div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>

              <template>
                <a-popover :trigger="['click', 'hover']" placement="bottom">
                  <div slot="content" class="content-box" style="width: 150px; user-select: none;">
                    <div
                      @click="copyAddress"
                      v-show="reverseFullName"
                      style="color: #7966FF;
                      word-wrap: break-word;
                      overflow-wrap: break-word;"
                    >
                      {{ getCutAddressText }}
                      <a-icon style="color: #333; padding-left: 10px; cursor: pointer;" type="copy"/>
                    </div>
                    <div style="margin: 12px 0; font-size: 15px; color: #2C3E50;cursor: pointer;" @click="goMyDomainsPage">Domains</div>
                    <div style="font-size: 15px; color: #2C3E50; cursor: pointer;" @click="goDerivativesPage">Derivatives</div>
                  </div>
                    <!-- address -->
                  <div class="address capsule-container">
                    <a-tooltip placement="bottom">
                      <template slot="title">
                        <span>disconnect</span>
                      </template>
                      <div class="line1" @click="disconnectWallet">
                        <a-icon class="disc-icon" type="logout"/>
                      </div>
                    </a-tooltip>
                    <div class="text">{{ getCutReverseName }}</div>
                    <div class="line1 user-icon">
                      <a-icon type="user"/>
                    </div>
                  </div>
                </a-popover>
              </template>

          <!-- disconnect -->
          <!-- <a-tooltip placement="bottom">
            <template slot="title">
              <span>disconnect</span>
            </template>
            <div class="disconnect capsule-container">
              <div class="line1">
                <a-icon type="disconnect" :style="{margin: '6px 12px 0px 0px'}"/>
              </div>
              <div class="text" @click="disconnectMask">{{ $t('disconnect') }}</div>
            </div>
          </a-tooltip> -->
        </div>
      </template>

      <template v-else >
        <div class="connect-metamask capsule-container"
          @click="connectWallet"
          >
          <div class="line1"><a-icon type="link" style="font-size: 18px;"/></div>
          <div style="margin-left: 8px;">{{ $t('connect') }}</div>
        </div>
      </template>
    </div>
      <backdrop v-show="visible">
        <wallet @visible="visibleHandler" :visible="visible"></wallet>
      </backdrop>
      <template>
      <div>
        <a-drawer
          placement="bottom"
          :closable="true"
          :visible="showDrawer"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
        >
        <div class="container">
          <div class="docs">
            <!-- <a-icon class="icon" type="setting"/> -->
            <a href="https://diddao.gitbook.io/docs" target="_blank">Documents</a>
          </div>
          <div class="docs">
            <!-- <a-icon class="icon" type="setting"/> -->
            <a href="#" class="disabled">Developers</a>
          </div>
          <div class="docs">
            <!-- <a-icon class="icon" type="setting"/> -->
            <a href="#" class="disabled">Applications</a>
          </div>
          <div class="btn-addr" v-if="login && address">

            <template>
              <a-popover :trigger="['click', 'hover']" placement="top">
                <div slot="content" class="content-box" style="width: 150px; user-select: none;">
                  <div style="font-size: 15px; color: #2C3E50; cursor: pointer;" @click="goMyDomainsPage">Domains</div>
                  <div style="margin: 12px 0; font-size: 15px; color: #2C3E50; cursor: pointer;" @click="goDerivativesPage">Derivatives</div>
                  <div
                    @click="copyAddress"
                    v-show="reverseFullName"
                    style="color: #7966FF;
                    word-wrap: break-word;
                    overflow-wrap: break-word;"
                  >
                    {{ getCutAddressText }}
                    <a-icon style="color: #333; padding-left: 10px; cursor: pointer;" type="copy"/>
                  </div>
                </div>
                <div class="btn-addr-container">
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      <span>disconnect</span>
                    </template>
                    <div class="line1" @click="disconnectWallet">
                      <a-icon class="disc-icon" type="logout"/>
                    </div>
                  </a-tooltip>
                  <!-- <div class="text">{{ getCutAddressText }}</div> -->
                  <div class="text">{{ getCutReverseName }}</div>
                  <div class="line1 user-icon">
                    <a-icon type="user"/>
                  </div>
                </div>
              </a-popover>
            </template>
          </div>
          <div v-else class="connect-btn" @click="connectWallet" >
            <div class="connect-btn-container">
              <div class="link-icon"><a-icon  class="icon" type="link"/></div>
              {{ $t('connect') }}
            </div>
          </div>
        </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { copy, changeChain } from '@/utils/func'
import backdrop from '@/components/backdrop.vue'
import wallet from '@/components/wallet.vue'
import logo from '@/components/logo.vue'

export default {
  name: 'pageHeader',
  components: {
    backdrop,
    wallet,
    logo
  },

  data () {
    return {
      white: false,
      active: false,
      visible: false,
      showDrawer: false,
      chain: {
        list: [
          // { name: 'Ethereum', chainID: '0x1', icon: require('@/assets/network/ethereum.svg') },
          { name: 'Polygon', chainID: '0x89', icon: require('@/assets/network/polygon.svg') }
          // { name: 'Mumbai', chainID: '0x13881', icon: require('@/assets/network/polygon.svg') }
        ]
      }
    }
  },

  watch: {
    address: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.getReverseFullName(newVal)
        }
      }
    }
  },

  computed: {
    ...mapGetters(['login', 'address', 'chainId', 'reverseFullName']),

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    getCutReverseName () {
      if (this.reverseFullName) {
        const len = this.reverseFullName.length
        if (len > 12) {
          return `${this.reverseFullName.slice(0, 4)}...${this.reverseFullName.slice(len - 5, len)}`
        }
        return this.reverseFullName
      }

      return this.getCutAddressText
    },

    getCutAddressText () {
      const len = this.address.length

      if (this.reverseFullName) {
        return `${this.address.slice(0, 6)}...${this.address.slice(len - 5, len)}`
      }

      return `${this.address.slice(0, 5)}...${this.address.slice(len - 4, len)}`
    },

    getNetworkInfoByChainId () {
      const currentNetwork = this.chain.list.find(item => item.chainID === this.chainId)
      return currentNetwork || {}
    },

    getNetworkNameByChainId () {
      const currentNetwork = this.chain.list.find(item => item.chainID === this.chainId)
      return currentNetwork?.name || 'Not Supported'
    },

    getNetworkIconByChainId () {
      const currentNetwork = this.chain.list.find(item => item.chainID === this.chainId)
      return currentNetwork?.icon || require('@/assets/network/unknown.png')
    }
  },

  created () {
    window.addEventListener('scroll', this.setMaskingScroll)

    window.onresize = () => {
      if (window.innerWidth > 960) {
        this.onClose()
      }
    }
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.setMaskingScroll)
  },

  methods: {
    ...mapActions(['setLogin', 'setAddress', 'setChainId', 'setReverseFullName']),

    goMyDomainsPage () {
      this.$router.push({ path: '/names' })
    },

    goDerivativesPage () {
      this.$router.push({ path: '/derivatives' })
    },

    afterVisibleChange (val) {
      console.log('afterVisibleChange', val)
    },

    openDrawer () {
      this.showDrawer = true
    },
    onClose () {
      this.showDrawer = false
    },

    setMaskingScroll () {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop > 30) {
        this.active = true
      } else {
        this.active = false
      }
    },

    visibleHandler (obj) {
      console.log('visible event')
      this.visible = obj.visible
    },

    goHomePage () {
      const homeUrl = `${process.env.VUE_APP_APP_URL}`

      // 当前窗口跳转
      // location.href = didassetUrl

      // 新窗口打开
      window.open(homeUrl)
    },

    getReverseFullName (addr) {
      if (this.reverseFullName) return

      console.log('Reverse arg:', addr)

      this.$contracts.resolver().reverse(addr).then(res => {
        console.log('Reverse:', res)
        this.setReverseFullName(res[1])
      }).catch(() => {
        // console.log(err)
      })
    },

    async connectWallet () {
      this.showDrawer = false

      if (!window.localStorage.getItem('walletconnect') && window.ethereum) {
        if (this.chainId === this.supportChainId) {
          const accounts = await window.ethereum.request({
            method: 'eth_accounts'
          })

          if (this.loggedIn(accounts[0])) return
        }
      }

      this.visible = true
      setTimeout(() => {
        document.body.style.overflow = 'hidden' // 直接使用有问题
      }, 150)
    },

    async disconnectWallet () {
      if (window.localStorage.getItem('walletconnect')) {
        try {
          await window.walletConnectWeb3Provider.disconnect()
        } catch (err) {
          console.log('walletConnectWeb3Provider disconnect:', err)
        }
      } else {
        window.localStorage.removeItem(this.address)
        this.setLogin(false)
        this.setAddress('')
      }
      // this.$emit('clear', {})
      // window.location.reload()
    },

    loggedIn (address) {
      if (window.localStorage.getItem(address)) {
        const loginInfo = JSON.parse(window.localStorage.getItem(address))
        const due = loginInfo.timestamp + 24 * 3600 * 1000 * 7
        if (loginInfo.isLogin && due > Date.now()) {
          this.setLogin(loginInfo.isLogin)
          this.setAddress(loginInfo.address)
          this.setChainId(loginInfo.chainId)
          return true
        } else {
          window.localStorage.removeItem(address)
          return false
        }
      } else {
        return false
      }
    },

    changeChain (obj) {
      changeChain(obj)
    },

    copyAddress () {
      copy(this.address)
    },

    toHomePage () {
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('~@/scss/iconfont.scss');

.ant-dropdown-menu-item {
  a {
    font-size: 14px;

    &:hover {
      color: $blueOth;
    }
    .iconfont {
      margin-right: 18px;
      padding-bottom: 4px;
      font-size: 20px;
      color: #666;
      vertical-align: middle;
    }
  }
}

.container {
  display: none;
  text-align: center;
  .docs {
    height: 40px;
    .icon {
      margin-right: 12px;
      font-size: 14px;
      color: #666;
    }

    a{
      font-size: 14px;
      color: #666;
      color: $blueOth;
    }

    &:first-child {
      margin-top: 16px;
    }
  }

  .btn-addr {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    font-size: 16px;
    .btn-addr-container {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      border-radius: 8px;
      background: #eee;
      background: rgba(58, 78, 255, 0.05);
      .text {
        min-width: 128px;
        padding: 0 16px;
        color: $blueOth;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  .connect-btn {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    font-size: 16px;
    .connect-btn-container {
      display: flex;
      align-items: center;
      padding: 8px 24px;
      color: #fff;
      border-radius: 8px;
      background: $mainBtnColor;
      cursor: pointer;
      user-select: none;
      .link-icon {
        padding-right: 12px;
        .icon {
          font-size: 18px;
        }
      }

      &:hover {
        // opacity: 0.7;
      }
    }
  }
}

.active {
  background: #fff;
  border-bottom: 1px solid #eee !important;
}
.page-header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(#eee, 0);
  z-index: 1000;
  overflow: hidden;
}
.header-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  height: 100%;
  .header-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      display: flex;
      align-items: center;
      cursor: pointer;
      img{
        height: 56px;
      }
  }

  .navbar{
    padding: 0 24px;
    padding-top: 4px;
    a{
      color: #666;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .community {
    margin-left: 16px;
    padding: 3px 12px;
    color: $blueOth;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    .icon {
      width: 20px;
      margin: 0 4px;
      padding-bottom: 2px;
    }
    .join {
      vertical-align: middle;
    }
  }
}

.menu-container {
  display: none;
  .menu-icon {
    font-size: 22px;
  }
}

  .capsule-container{
    display: flex;
    align-items: center;
    padding: 18px 12px 16px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    border: 2px solid $boxShadowColor;
    border-radius: 8px;
    background: #fff;
    &:hover {
      box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.2);
    }
  }

  .chain{
    min-width: 148px;
    cursor: pointer;

    .network-name{
      flex: 1;
      padding: 0 22px;
      display: flex;
      align-items: center;
      font-size: 15px;
      vertical-align: middle;
    }

    .icon{

      i {
        vertical-align: middle;
      }
    }

    &.unknown{
      color: #FF3333;
      background: #fff0f0;
      border: 1px solid #FF3333;
    }
  }
  .disconnect {
    margin-left: 16px;
    cursor: pointer;
    user-select: none;
  }

  .connect-metamask, .add-Field-network {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    font-size: 15px;
    &:hover {
      box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.2);
    }
    .metamask-icon {
      width: 20px;
      padding-bottom: 2px;
      vertical-align: middle;
    }
  }

  .info{
    display: flex;
  }
  .address {
    margin-left: 20px;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    .user-icon {
      font-size: 16px;
    }
    .disc-icon, .copy-address {
      &:active {
        color: #b15be6;
      }
    }
    .text{
      min-width: 124px;
      color: $blueOth;
      padding: 0 16px;
    }
  }
}

.network-item{
  display: flex;
  // line-height: 24px;
}

.network-item-icon{
  img{
    width: 18px;
    margin-right: 8px;
    padding-bottom: 1px;
  }

  .icon-eth-t {
    width: 12px;
  }
  .icon-goerli-t {
    width: 19px;
  }
  .icon-eth {
      width: 13px;
      margin-right: 10px;
    }
}

.navmenu{
  width: 200px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px #ddd;
}

// test
.navbar{
  .icon {
    padding-left: 2px;
    margin-right: 24px;
    font-size: 16px;
    color: #666;
  }
  a{
    font-size: 14px;
    color: #666;
  }
}

@media screen and (max-width: $mobileWidth) {
  .container {
    display: block;
  }
  .header-container{
    width: 100%;
    padding-right: 16px;
    .logo{
      padding-left: 12px;

      ::v-deep img{
        height: 28px !important;
      }
    }
    .menu-container {
      display: block;
    }
    .connect-metamask {
      display: none;
    }
    .dropdown {
      display: none;
    }
    .info {
      display: none;
    }
  }
}

</style>
